import {AddressValueSchema} from "../types/inputTypes";

export default function addressInputValidator(_: any, value: any){

    if(value?.manual === true){
        try{
            AddressValueSchema.parse(value)
            return Promise.resolve();
        }catch (e) {
            return Promise.reject('Completa tutti i campi');
        }
    }else {
        try{
            AddressValueSchema.parse(value)
            return Promise.resolve();
        }catch (e) {
            return Promise.reject('Seleziona un indirizzo');
        }
    }
}
