import {SubscriptionType} from "@tipubly/nif-types";
import {blue, gray, green, grey} from "@ant-design/colors";
import {Button, Flex} from "antd";
import {ClockCircleOutlined, PauseOutlined} from "@ant-design/icons";
import {subscription} from "../../App";
import {useState} from "react";
import ConfirmModal from "../ConfirmModal";
import {cancelSubscription, renewSubscription} from "../../utils/stripe";
import dayjs from "dayjs";
import Wrapper from "../Wrapper";

interface SubscriptionProps {
}

const Subscription = (props: SubscriptionType) => {

    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [renewConfirm, setRenewConfirm] = useState(false);
    const [loadingConfirm, setLoadingConfirm] = useState(false);

    return (
        <div>
            {props.status === 'trialing' &&
                <div
                    style={{backgroundColor: blue[0], padding: 20, borderRadius: 20, color: blue[4], marginBottom: 15}}>
                    <Flex align={'center'}>
                        <div>
                            <ClockCircleOutlined style={{fontSize: 20, marginRight: 10}}/>
                        </div>
                        <div>
                            <p style={{fontWeight: 600}}>La prova gratuita scadrà tra {props.trial_days_left} giorni</p>
                        </div>
                    </Flex>
                </div>
            }
            <Wrapper p={20}>
                <Flex justify={"space-between"}>
                    <h4 style={{color: grey[2]}}>Abbonamento</h4>
                    <SubscriptionStatusCip/>
                </Flex>
                <div>
                    <div>
                        <h4 style={{
                            marginTop: 5,
                            fontSize: 22
                        }}>Abbonamento {props.interval === 'month' ? 'mensile' : 'annuale'}</h4>
                        {props.extra_sales_points_number > 0 &&
                            <p>+{props.extra_sales_points_number} Punt{props.extra_sales_points_number === 1 ? 'o' : 'i'} vendita
                                extra</p>
                        }
                    </div>
                    {props.cancel_at ?
                        <p style={{marginTop: 6, color: grey[2]}}>L'abbonamento terminerà
                            il {dayjs(props.cancel_at as any * 1000).format('DD/MM/YY')} e non sarà rinnovato</p> :
                        <p style={{marginTop: 6, color: grey[2]}}>L'abbonamento sarà rinnovato automaticamente
                            il {props.renewal_date}</p>
                    }

                    {props.cancel_at ?
                        <Flex justify={'end'}>
                            <Button onClick={() => {
                                setRenewConfirm(true)
                            }} type={'primary'} style={{
                                marginTop: 10,
                            }}>Rinnova
                                abbonamento</Button>
                        </Flex>

                        :
                        <p onClick={() => {
                            setDeleteConfirm(true)
                        }} style={{
                            marginTop: 10,
                            color: grey[2],
                            textDecoration: 'underline',
                            cursor: "pointer"
                        }}>Annulla
                            abbonamento</p>
                    }

                </div>
            </Wrapper>
            <ConfirmModal
                open={deleteConfirm}
                loading={loadingConfirm}
                setOpen={setDeleteConfirm}
                danger={true}
                title={"Annulla Abbonamento"}
                buttonText={"Annulla abbonamento"}
                onConfirm={() => {
                    setLoadingConfirm(true);
                    cancelSubscription(subscription.value.subscription_id);
                }}
            >
                <p>
                    L'abbonamento sarà annullato al termine del periodo di fatturazione
                    corrente.
                </p>
            </ConfirmModal>
            <ConfirmModal
                open={renewConfirm}
                loading={loadingConfirm}
                setOpen={setRenewConfirm}
                danger={false}
                title={"Rinnova Abbonamento"}
                buttonText={"Rinnova abbonamento"}
                onConfirm={() => {
                    setLoadingConfirm(true);
                    renewSubscription(subscription.value.subscription_id);
                }}
            >
                <p>
                    L'abbonamento sarà rinnovato automaticamente al termine del periodo di
                    fatturazione corrente.
                </p>
            </ConfirmModal>
        </div>

    );
}
export default Subscription;

function SubscriptionStatusCip() {
    return (
        <>
            {subscription.value.cancel_at ? (
                <p
                    style={{
                        padding: "3px 10px",
                        backgroundColor: gray[0],
                        borderRadius: 5,
                        color: "white",
                        fontSize: 13,
                        width: "fit-content",
                    }}
                >
                    Annullato
                </p>
            ) : subscription.value.status === "paused" ? (
                <p
                    style={{
                        padding: "3px 10px",
                        backgroundColor: gray[1],
                        borderRadius: 5,
                        color: "white",
                        fontSize: 13,
                        width: "fit-content",
                    }}
                >
                    <PauseOutlined/> In Pausa
                </p>
            ) : subscription.value.status === "trialing" ? (
                <p
                    style={{
                        padding: "3px 10px",
                        backgroundColor: blue[1],
                        borderRadius: 5,
                        color: blue[4],
                        fontSize: 13,
                        fontWeight: 600,
                        width: "fit-content",
                    }}
                >
                    In prova
                </p>
            ) : (
                <p
                    style={{
                        padding: "3px 10px",
                        backgroundColor: green[1],
                        borderRadius: 5,
                        color: green[6],
                        fontSize: 13,
                        fontWeight: 600,
                        width: "fit-content",
                    }}
                >
                    Attivo
                </p>
            )

            }

        </>

    )
}
