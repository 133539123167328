import {
    Button,
    Form,
    Input,
    message,
    Modal,
    Skeleton,
    Space
} from "antd";
import {
    DeleteOutlined,
    PlusOutlined,
    ShopOutlined,
} from "@ant-design/icons";
import {useState} from "react";
import {subscription, userId} from "../../App";
import {blue, red, yellow} from "@ant-design/colors";
import ConfirmModal from "../ConfirmModal";
import {SalesPointType} from "@tipubly/nif-types";
import AddressInput from "../AdressInput";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {authDelete, authGet, authPost, authPut} from "../../utils/authCalls";
import Wrapper from "../Wrapper";

interface SalesPointWithDefault extends SalesPointType {
    is_default: boolean,
    id: string
}

const SalesPointsForm = () => {
    const [loading, setLoading] = useState(true);
    const [editModal, setEditModal] = useState<SalesPointWithDefault | 'new'>();
    const [form] = Form.useForm();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmCreate, setConfirmCreate] = useState(false);
    const [confirmRenew, setConfirmRenew] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const queryClient = useQueryClient()

    async function getSalesPoints(){
        const {data} = await authGet('/v1/sales-points/')
        return data
    }

    const {data, isLoading} = useQuery({
        queryKey: ['salesPoints'],
        queryFn: async (): Promise<SalesPointWithDefault[]> => await getSalesPoints()
    })

    function handleOpenEdit(item: SalesPointWithDefault | 'new') {
        setEditModal(item);
        if(item !== 'new'){
            form.setFieldsValue({
                salesPointName: item.salesPointName,
                salesPointAddress: item.salesPointAddress,
            });
        }else {
            form.resetFields()
        }
    }

    async function createSalesPoint() {
        setConfirmLoading(true)
        const values = form.getFieldsValue()
        await authPost('/v1/sales-points/', values)
        await queryClient.invalidateQueries({queryKey: ['salesPoints']})
        message.success("Punto vendita aggiunto");
        setConfirmCreate(false)
        setEditModal(undefined)
        setConfirmLoading(false)
    }

    async function editSalesPoint(){
        setLoading(true)
        const values = form.getFieldsValue()
        if(editModal && editModal !== 'new'){
            try{
                await authPut('/v1/sales-points', {...values, id: editModal.id})
            } catch (e) {
                message.error("Qualcosa è andato storto, riprova")
                return
            }
        }else {
            message.error("Qualcosa è andato storto, riprova")
            return
        }
        setLoading(false)
        setEditModal(undefined)
        await queryClient.invalidateQueries({queryKey: ['salesPoints']})
        message.success("Informazioni aggiornate");
    }

    async function deleteSalesPoint(){
        if(!editModal || editModal == 'new'){
            return
        }
        try{
            await authDelete(`/v1/sales-points/${editModal.id}`)
        }catch (e) {
            message.error("Qualcosa è andato storto, riprova")
        }finally {
            setEditModal(undefined)
            setConfirmDelete(false)
            setConfirmLoading(false)
            await queryClient.invalidateQueries({queryKey: ['salesPoints']})
            message.success("Informazioni aggiornate");
        }
    }

    async function onSubmitForm(values: any) {
        if (editModal === 'new') {
            setConfirmCreate(true);
            return
        }else{
            await editSalesPoint()
        }
    }

    return (
        <Wrapper
            p={20}
            mt={10}
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 30,
            }}
        >
            <h3>Punti vendita</h3>
            {isLoading || !data ? (
                <Skeleton active/>
            ) : (
                <>
                    {data.map((item) => (
                        <div
                            key={item.id}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Space size={"middle"}>
                                {!item.is_default && subscription.value.status === 'no subscription' ?
                                <div><p style={{fontSize: 16, fontWeight: 600}}>+15€</p><p>/mese</p></div>
                                :
                                    <ShopOutlined className={'hide-mob'}
                                                  style={{fontSize: 25, color: item.is_default ? blue[4] : 'grey'}}/>
                                }

                                <div>
                                    <h3>
                                        {item.is_default && (
                                            <p
                                                style={{
                                                    fontSize: 12,
                                                    color: blue[4],
                                                    width: "fit-content",
                                                    borderRadius: 10,
                                                }}
                                            >
                                                Principale
                                            </p>
                                        )}
                                        {item.salesPointName}{" "}
                                    </h3>
                                    <p>{!item.salesPointAddress.manual && item.salesPointAddress.value?.label}</p>
                                </div>
                            </Space>
                            <Button

                                onClick={() => handleOpenEdit(item)}
                            >
                                Modifica
                            </Button>
                        </div>
                    ))}
                    <Button onClick={() => handleOpenEdit("new")}>
                        <PlusOutlined/>
                        Aggiungi punto vendita
                    </Button>
                </>
            )}
            {editModal && (
                <Modal
                    okText={"Salva"}
                    footer={
                        <>
                            <Button
                                onClick={() => {
                                    setEditModal(undefined);
                                    form.resetFields()
                                }}
                            >
                                Annulla
                            </Button>
                            {
                                (editModal !== 'new' && !editModal.is_default) &&
                                <Button onClick={() => setConfirmDelete(true)} danger>
                                    <DeleteOutlined/> Elimina
                                </Button>
                            }
                            <Button onClick={() => form.submit()} type={"primary"}>Salva</Button>
                        </>
                    }
                    cancelText={editModal === "new" ? "Annulla" : "Elimina"}
                    onOk={() => form.submit()}
                    onCancel={() => setEditModal(undefined)}
                    open={!!editModal}
                >
                    <Form
                        onFinish={onSubmitForm}
                        form={form}
                        layout={"vertical"}
                        style={{paddingTop: 30, paddingBottom: 10}}
                    >
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Inserisci il nome del punto vendita",
                                },
                            ]}
                            required={false}
                            name={"salesPointName"}
                            label={"Nome del punto vendita"}
                        >
                            <Input
                                placeholder={"Nome del punto vendita"}
                            />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Inserisci l'indirizzo del punto vendita",
                                },
                            ]}
                            required={false}
                            name={"salesPointAddress"}
                            label={"Indirizzo"}
                        >
                            <AddressInput/>
                        </Form.Item>
                    </Form>
                    <ConfirmModal loading={confirmLoading} open={confirmDelete} setOpen={setConfirmDelete}
                                  onConfirm={() => {
                                      setConfirmLoading(true);
                                      deleteSalesPoint()
                                  }} title={'Attenzione'} buttonText={'Annulla abbonamento'} danger={true}>
                        <p>L'abbonamento rimarrà attivo fino al termine del periodo di fatturazione corrente e poi sarà
                            eliminato automaticamente</p>
                    </ConfirmModal>
                    <ConfirmModal loading={confirmLoading} open={confirmRenew} setOpen={setConfirmRenew}
                                  onConfirm={() => {
                                      setConfirmLoading(true);
                                  }} title={'Rinnova'} buttonText={'Rinnova abbonamento'} danger={false}>
                        <p>L'abbonamento verrà rinnovato automaticamente al termine del periodo di fatturazione</p>
                    </ConfirmModal>
                    <ConfirmModal loading={confirmLoading} open={confirmCreate} setOpen={setConfirmCreate}
                                  onConfirm={() => createSalesPoint()} title={'Aggiungi'} buttonText={'Aggiungi nuovo'}
                                  danger={false}>
                        <div><p style={{fontSize: 25, fontWeight: 600}}>+15€ <span
                            style={{fontSize: 16}}>/mese + iva</span></p><p>Il tuo abbonamento verrà aggiornato</p>
                        </div>
                    </ConfirmModal>
                </Modal>
            )}
        </Wrapper>
    );
};
export default SalesPointsForm;
