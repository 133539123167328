import {Button, Flex, Form, Input, Radio, Select, Space} from "antd";
import {MinusCircleOutlined, PlusCircleFilled} from "@ant-design/icons";
import {useState} from "react";
import {blue} from "@ant-design/colors";
import CustomRadioGroup from "../CustomRadioGroup";
import addressInputValidator from "../../utils/addressInputValidator";
import AddressInput from "../AdressInput";
import Wrapper from "../Wrapper";

interface Step2Props {
    onSubmit: () => void,
    setFormValues: (values: any) => void
    formValues: any
}

const Step2 = ({onSubmit, setFormValues, formValues}: Step2Props) => {
    const [form] = Form.useForm();

    async function onFinish(values: any) {
        setFormValues({...values})
        onSubmit()
    }

    return (
        <div data-matomo-form={true} data-matomo-name="onBoarding">
            <Form onValuesChange={(_, allFields) => {
                setFormValues({...allFields})
            }} initialValues={formValues} form={form} onFinish={onFinish} layout={'vertical'}>
                <div style={{marginBottom: 20}}>
                    <Form.Item name={'hasOneSalesPoint'}>
                        <CustomRadioGroup
                            options={[{value: true, label: 'Un punto vendita'}, {
                                value: false,
                                label: 'Più di un punto vendita'
                            }]}/>
                    </Form.Item>
                </div>
                {!formValues.hasOneSalesPoint &&
                    <Form.Item name={'mainSalesPointName'}
                               label={`Nome Punto vendita ${!formValues.hasOneSalesPoint ? 'principale' : ''}`}>
                        <Input/>
                    </Form.Item>
                }
                <Form.Item validateTrigger={'submit'} rules={[{validator: addressInputValidator}]}
                           name="mainSalesPointAddress"
                           label={`Indirizzo punto vendita ${!formValues.hasOneSalesPoint ? 'principale' : ''}`}>
                    <AddressInput/>
                </Form.Item>
                {!formValues.hasOneSalesPoint &&
                    <Form.List name={"extraSalesPoints"}>
                        {(fields, {add, remove}) => (
                            <Space
                                direction={"vertical"}
                                style={{width: "100%"}}
                                size={"middle"}
                            >
                                {fields.map(({key, name, ...restField}) => (
                                    <Wrapper
                                        key={name}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                color: "red",
                                                alignItems: "center",
                                                marginBottom: 10
                                            }}
                                        >
                                            <p style={{color: 'gray', fontWeight: 600}}>
                                                +15€/mese + iva
                                            </p>
                                            <Button
                                                onClick={() => remove(name)}
                                                danger
                                                type={"text"}
                                            >
                                                Rimuovi
                                                <MinusCircleOutlined/>
                                            </Button>
                                        </div>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Inserisci il nome del punto vendita",
                                                },
                                            ]}
                                            required={false}
                                            name={[name, "salesPointName"]}
                                            label={"Nome del punto vendita"}
                                        >
                                            <Input
                                                placeholder={"Nome del punto vendita"}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Inserisci l'indirizzo del punto vendita",
                                                },
                                            ]}
                                            required={false}
                                            name={[name, "salesPointAddress"]}
                                            label={"Indirizzo"}
                                        >
                                            <AddressInput/>
                                        </Form.Item>
                                    </Wrapper>
                                ))}
                                <Form.Item>
                                    <Button
                                        onClick={() => add()}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <PlusCircleFilled color={"grey"}/>
                                        Aggiungi un punto vendita
                                    </Button>
                                </Form.Item>
                            </Space>
                        )}
                    </Form.List>
                }
                <Button type={'primary'} size={'large'}
                        style={{width: '100%', marginTop: 18, borderRadius: 30, height: 43}}
                        htmlType={'submit'}>Continua</Button>
            </Form>
        </div>);
}
export default Step2;
