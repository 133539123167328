import {Button, Card, Dropdown, Modal, Space} from "antd";

import {userId} from "../App";
import {DeleteOutlined, DownOutlined, EllipsisOutlined, SmileOutlined} from "@ant-design/icons";
import {gray} from "@ant-design/colors";
import {useState} from "react";
import {supabase} from "../utils/supabase";
import Wrapper from "./Wrapper";
import {bucketUrl} from "../utils/urls";


const NotificationCard = ({item, setSelected, index, setRefresh}: any) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);

    async function deleteNotification() {
        try {
            setDeleting(true)

            if (item.image) {
                const {data, error: deleteError} = await supabase
                    .storage
                    .from('main')
                    .remove([userId.value + '/' + item.id + '.jpeg'])

                if (deleteError) {
                    throw deleteError
                }
            }

            const {error} = await supabase
                .from('notifications')
                .delete()
                .eq('id', item.id)

            if (error) {
                throw error
            }
            setRefresh(Math.random())
            setModalOpen(false)
        } catch (e) {

        }
    }



    const image = item.image ? bucketUrl + userId.value + '/' + item.id + '.jpeg' : bucketUrl + userId.value + '/logo.png'
    const items = [
        {
            key: '1',
            label: (
                <a onClick={() => setModalOpen(true)}>
                    Elimina
                </a>
            ),
            danger: true,
            icon: <DeleteOutlined/>
        },

    ];


    return (
        <>
            <Modal centered open={modalOpen} onCancel={() => setModalOpen(false)} okText={'Elimina'}
                   cancelText={'Annulla'} okButtonProps={{danger: true, loading: deleting, disabled: deleting}}
                   cancelButtonProps={{disabled: deleting, loading: deleting}} onOk={deleteNotification}>
                <h3>Vuoi davvero eliminare questa notifica?</h3>
                <p>I tuoi clienti non saranno più in grado di vederla. Questa azione è irreversibile</p>
            </Modal>
            <Wrapper style={{width: '100%', maxWidth: 450, overflow: 'hidden'}}>
                <div style={{display: "flex", gap: 10}}>
                    <img alt={'notification'} style={{objectFit: 'cover', borderRadius: 10}} src={image} width={80}
                         height={80}/>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}><p style={{
                        margin: 0,
                        fontSize: 12,
                        color: gray[0],
                        fontWeight: 600
                    }}>{new Date(item.created_at).toLocaleDateString('en-UK')}</p><h3
                        style={{margin: 0}}>{item.title}</h3></div>
                </div>
                <p style={{color: gray[3], marginTop: 10}}>{item.text}</p>
                <div style={{textAlign: 'right'}}>
                    <Dropdown menu={{items}}>
                        <button onClick={(e) => e.preventDefault()} style={{
                            backgroundColor: '#f7f7f7',
                            width: 30,
                            height: 30,
                            borderRadius: 20,
                            border: 'none',
                            cursor: 'pointer',
                            color: gray[0],
                            fontSize: 18
                        }}><EllipsisOutlined style={{marginTop: 3}}/></button>
                    </Dropdown>
                </div>

            </Wrapper></>);
}
export default NotificationCard;
