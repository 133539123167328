import {Button, Flex, Form, Grid, Input, Select, Space, Steps} from "antd";
import {useEffect, useState} from "react";
import {
    ArrowLeftOutlined,
    CheckOutlined,
    CheckSquareOutlined,
    LogoutOutlined,
    MinusCircleOutlined,
    PlusCircleFilled
} from "@ant-design/icons";
import Step2 from "../components/onBoarding/Step2";
import Step1 from "../components/onBoarding/Step1";
import Step3 from "../components/onBoarding/Step3";
import {theme} from 'antd';
import PaymentForm from "../components/onBoarding/PaymentForm";
import {SelectOption} from "../types/inputTypes";
import {supabase} from "../utils/supabase";
import {authPost} from "../utils/authCalls";
import {CreateShopSchema, SubscriptionsType, SubscriptionType} from "@tipubly/nif-types";
import {userId} from "../App";
import Loader from "../components/Loader";
import '../styles/layouts.css'
import {signOut} from "../utils/signOut";
import {trackFormConversion} from "../utils/Matomo/tracking_functions";

interface NewOnboardingPageProps {

}

const OnboardingPage = () => {

    const [step, setStep] = useState(1);
    const [formValues, setFormValues] = useState<any>({
        id: userId.value,
        subscription: {subscriptionType: 'yearly'},
        extraSalesPoints: [],
        hasOneSalesPoint: true
    });

    const heroes = [
        {title: ''},
        {title: 'Benvenuto 👋', subtitle: 'Facciamo del tuo profilo qualcosa di speciale'},
        {title: 'Punti vendita', subtitle: 'Aggiungi i tuoi punti vendita'},
        {title: 'Ci siamo quasi...', subtitle: ''},
        {title: '', subtitle: ''}
    ]

    const [loading, setLoading] = useState(true);
    const [businessCategories, setBusinessCategories] = useState<SelectOption[]>(
        [],
    );
    const [subscription, setSubscription] = useState<SubscriptionType>();
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        async function getInitialData() {
            const {data, error} = await supabase
                .from("business_categories")
                .select("id, italian")
                .order("italian");
            if (data) {
                const formattedOptions = await Promise.all(
                    data.map((item) => ({value: item.id, label: item.italian})),
                );
                setBusinessCategories(formattedOptions);
            }
        }

        getInitialData().then(() => setLoading(false));
    }, []);

    async function createShop() {
        setSaving(true)
        let body = {...formValues}

        if (!formValues.mainSalesPointName || formValues.hasOneSalesPoint) {
            body.mainSalesPointName = formValues.business_name
        }

        if(formValues.hasOneSalesPoint){
            body.extraSalesPoints = []
        }


        try {
            await CreateShopSchema.parseAsync(body)
            const {data} = await authPost('/v1/shops/', body)

            if (data.next === 'toPayment' && data?.subscriptionSummary) {
                setSubscription(data.subscriptionSummary)
                setStep(4)
                setSaving(false)
                trackFormConversion('onBoarding')
            }else {
                window.location.reload()
            }

        } catch (e: any) {
            console.log(e.message)
            setSaving(false)
        }finally {
        }
    }

    return (
        <div>
            {
                step > 1 && step < 4 &&
                <div style={{position: 'absolute', top: 20, left: 20, cursor: 'pointer'}} onClick={() => {
                    setStep(step - 1)
                }}>
                    <ArrowLeftOutlined style={{fontSize: 20}}/>
                </div>

            }
            <div className='contentContainer'>
                <div style={{maxWidth: '400px', margin: 'auto', width: '100%'}}>
                    {!saving &&
                        <div style={{textAlign: 'center', marginBottom: 50}}>
                            <h1>{heroes[step].title}</h1>
                            <p style={{color: 'grey', fontSize: 15}}>{heroes[step].subtitle}</p>
                        </div>
                    }
                    {saving ?
                        <div>
                            <h2 style={{textAlign: 'center', marginBottom: 30}}>Stiamo creando il tuo negozio</h2>
                            <Loader/>
                        </div> :
                        step === 1 ?
                            <Step1 loading={loading} businessCategories={businessCategories}
                                   setFormValues={(values) => setFormValues({...formValues, ...values})}
                                   formValues={formValues} onSubmit={() => setStep(2)}/>
                            : step === 2 ?
                                <div>
                                    <Step2 setFormValues={(values) => setFormValues({...formValues, ...values})}
                                           formValues={formValues} onSubmit={() => {
                                        setStep(3)
                                    }}/>
                                </div>
                                : step == 3 ?
                                    <Step3 setFormValues={(values) => setFormValues({...formValues, ...values})}
                                           formValues={formValues} onSubmit={() => {
                                        createShop()
                                    }}

                                    /> : step == 4 && subscription &&
                                    <PaymentForm {...subscription}/>
                    }
                </div>
            </div>
            <div className={'onboardingSideBar'}>
                <Flex style={{width: 280, flexDirection: 'column', height: '100%'}}
                      justify={'space-evenly'}>
                    <Flex align={'center'} style={{fontWeight: 600, fontSize: 20}} gap={5}>
                        <img width={35} src={'./favicon.ico'}/>
                        Next is Free
                    </Flex>
                    <div>
                        <h2 style={{marginBottom: 30, fontSize: 28}}>Crea il tuo negozio in pochi click</h2>
                        <StepCard step={step} number={1} title={'Informazioni di base'}/>
                        <div style={{
                            height: 30,
                            backgroundColor: 'white',
                            width: 2,
                            marginLeft: 20,
                            marginTop: 7,
                            marginBottom: 7
                        }}/>
                        <StepCard step={step} number={2} title={'Punti vendita'}/>
                        <div style={{
                            height: 30,
                            backgroundColor: 'white',
                            width: 2,
                            marginLeft: 20,
                            marginTop: 7,
                            marginBottom: 7
                        }}/>
                        <StepCard step={step} number={3} title={'Dati di Fatturazione'}/>
                    </div>
                    <p onClick={signOut} style={{fontSize: 12, cursor: 'pointer'}}>
                        <LogoutOutlined/> Logout
                    </p>
                </Flex>
            </div>
        </div>
    );
}
export default OnboardingPage;

function StepCard({number, title, step}: any) {
    return (
        <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
            <Flex justify={'center'} align={'center'}
                  style={{
                      borderRadius: 40,
                      border: '1.5px solid white',
                      width: 40,
                      height: 40,
                      backgroundColor: step > number ? 'white' : undefined
                  }}>
                {step > number ?
                    <div><CheckOutlined style={{color: '#000F35'}}/></div>
                    :
                    <div>{number}</div>
                }
            </Flex>
            <div>{title}</div>
        </div>
    )
}
