import {Button, Form, Input, Select} from "antd";
import AdressInput from "../AdressInput";
import PromoCodeInput from "../SubscriptionInput";
import addressInputValidator from "../../utils/addressInputValidator";
import {subscription} from "../../App";

interface Step3Props {
    onSubmit: () => void,
    setFormValues: (values: any) => void
    formValues: any
}

const Step3 = ({onSubmit, setFormValues, formValues}: Step3Props) => {
    const [form] = Form.useForm();
    async function onFinish(values: any) {
        setFormValues({...values})
        onSubmit()
    }


    return (
        <div data-matomo-form={true} data-matomo-name="onBoarding">
            <Form onValuesChange={(_, allFields)=>{setFormValues({...allFields})}} initialValues={formValues} form={form} onFinish={onFinish} style={{maxWidth: '400px', margin: 'auto'}} layout={'vertical'}>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Inserisci la partita iva",
                    },
                    {
                        len: 11,
                        message: 'Partita iva non valida'
                    }
                ]}
                           required={false}
                           name={"vat"} label={'Partita IVA'}>
                    <Input placeholder={'Partita IVA'}/>
                </Form.Item>
                <Form.Item validateTrigger={'submit'} rules={[{validator: addressInputValidator}]} name={'billingAddress'} label={'Indirizzo di fatturazione'}>
                    <AdressInput onlyManual/>
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Inserisci la pec",
                    },
                    {
                        type: "email",
                        message: "Inserisci una pec valida",
                    },
                ]}
                           required={false}
                           name={"pec"} label={'Pec'}>
                    <Input placeholder={'Pec'}/>
                </Form.Item>
                <Form.Item name={'subscription'} label={'Codice sconto'}>
                   <PromoCodeInput/>
                </Form.Item>
                <Button type={'primary'} size={'large'}
                        style={{width: '100%', marginTop: 20, borderRadius: 30, height: 43}}
                        htmlType={'submit'}>Continua</Button>
            </Form>
        </div>);
}
export default Step3;
