import "./App.css";
import MainLayout from "./layouts/MainLayout";
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import CustomizePage from "./pages/CustomizePage";
import React, {useEffect, useState} from "react";
import LoginPage from "./pages/LoginPage";
import FinishSignUpPage from "./pages/FInishSignUpPage.jsx";
import {supabase} from "./utils/supabase";
import SettingsPage from "./pages/SettingsPage";
import {computed, signal} from "@preact/signals-react";
import SignUpPage from "./pages/SignUpPage";
import NotificationsPage from "./pages/NotificationsPage";
import AnalyticsPage from "./pages/AnalyticsPage";
import NewNotificationPage from "./pages/NewNotificationPage";
import ConfirmEmailPage from "./pages/ConfirmEmailPage";
import {AnimatePresence} from "framer-motion";
import BillingsPage from "./pages/BillingsPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import dayjs from "dayjs";
import TransactionsPage from "./pages/TransactionsPage";
import ResourcesPage from "./pages/ResourcesPage";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import OnboardingPage from "./pages/OnboardingPage";
import {SubscriptionType} from "@tipubly/nif-types";
import RouteChangeDetector from "./utils/Matomo/RouteChangeDetector";
//date locale set
require("dayjs/locale/it");
dayjs().locale("it");

export const logged = signal(false);
export const userId = signal("");
export const businessName = signal(undefined);
export const verified = signal(false);

export const subscription = signal<SubscriptionType>({
    status: "loading",
    cardBrand: undefined,
    cardLast4: undefined,
    default_payment_method: undefined,
    extra_sales_points_number: 0
});

export const onBoarded = signal(false);
export const welcome = signal(true);
export const completed = signal(true);
export const published = signal(false);
export const publishedComputed = computed(() => {
    return (
        completed.value &&
        published.value &&
        subscription.value.status !== "no subscription" && subscription.value.status !== "incomplete"
    );
});

export const changePassword = signal(false);

export const queryClient = new QueryClient();

function App() {
    const [loading, setLoading] = useState(true);

    const location = useLocation();


    useEffect(() => {
        supabase.auth.onAuthStateChange(async (event, session) => {
            if (event == "PASSWORD_RECOVERY") {
                changePassword.value = true;
            }
        });
    }, []);

    async function checkAuth() {
        const {
            data: {user},
            error,
        } = await supabase.auth.getUser();

        if (error) {
            logged.value = false;
            return;
        }
        if (user) {
            //check if user is a shop
            if (user.user_metadata.shop !== true) {
                return;
            }
            //check if user is onboarded
            const {data: shop, error} = await supabase
                .from("shops")
                .select("id, business_name, completed, public")
                .eq("id", user.id);

            if (shop?.length === 1) {
                businessName.value = shop[0].business_name
                onBoarded.value = true;
                completed.value = shop[0].completed;
                published.value = shop[0].public;
            }
            userId.value = user.id;
            verified.value = true;
            logged.value = true;
        }
    }

    useEffect(() => {
        checkAuth().then(() => setLoading(false));
    }, []);

    if (loading)
        return (
            <div style={{position: "absolute", top: "50%", left: "50%"}}>
                <img style={{width: 50, height: 50}} src={"./loader.svg"}/>
            </div>
        );

    return (
        <AnimatePresence mode={"wait"}>
            <>
                {changePassword.value ? (
                    <Routes>
                        <Route
                            path={"/update-password"}
                            element={<ChangePasswordPage/>}
                        ></Route>
                        <Route
                            path={"*"}
                            element={<Navigate replace to={"/update-password"}/>}
                        />
                    </Routes>
                ) : !logged.value || !userId.value ? (
                    <Routes location={location} key={location.pathname}>
                        <Route path={"/login"} element={<LoginPage/>}/>
                        <Route path={"/sign-up"} element={<SignUpPage/>}/>
                        <Route path={"/confirm-email"} element={<ConfirmEmailPage/>}/>
                        <Route path={"/welcome"} element={<FinishSignUpPage/>}/>
                        <Route path={"*"} element={<Navigate replace to={"/login"}/>}/>
                    </Routes>
                ) : onBoarded.value ? (
                    <MainLayout>
                        <QueryClientProvider client={queryClient}>
                            <Routes>
                                <Route path={"/analytics"} element={<AnalyticsPage/>}/>
                                {
                                    subscription.value.status !== 'incomplete' &&
                                    <>
                                        <Route path={"/transactions"} element={<TransactionsPage/>}/>
                                        <Route
                                            path={"/notifications"}
                                            element={<NotificationsPage/>}
                                        />
                                        {publishedComputed.value &&
                                            <Route
                                                path={"/notifications/new"}
                                                element={<NewNotificationPage/>}
                                            />
                                        }

                                        <Route path={"/customize"} element={<CustomizePage/>}/>
                                        <Route path={"/resources"} element={<ResourcesPage/>}/>
                                        <Route path={"/billings"} element={<BillingsPage/>}/>
                                        <Route path={"/settings"} element={<SettingsPage/>}/>
                                    </>
                                }
                                <Route
                                    path={"*"}
                                    element={<Navigate replace to={"/analytics"}/>}
                                />
                            </Routes>
                            <ReactQueryDevtools initialIsOpen={false}/>
                        </QueryClientProvider>
                    </MainLayout>
                ) : (
                    <Routes>
                        <Route path={"/onboard"} element={<OnboardingPage/>}/>
                        <Route path={"/*"} element={<Navigate replace to={"/onboard"}/>}/>
                    </Routes>
                )}
            </>
        </AnimatePresence>
    );
}

export default () => {
    return (
        <BrowserRouter>
            <RouteChangeDetector/>
            <App/>
        </BrowserRouter>
    );
};
