import {z} from "zod";

export type SelectOption = {
    value: string,
    label: string
}

export interface TomTomResult {
    type: string;
    id: string;
    score: number;
    info: string;
    poi: {
        name: string;
        categorySet: { id: number }[];
        categories: string[];
        classifications: {
            code: string;
            names: { nameLocale: string; name: string }[];
        }[];
    };
    address: {
        streetName: string;
        municipality: string;
        countrySecondarySubdivision: string;
        countrySubdivision: string;
        countrySubdivisionName: string;
        countrySubdivisionCode: string;
        postalCode: string;
        countryCode: string;
        country: string;
        countryCodeISO3: string;
        freeformAddress: string;
        localName: string;
        streetNumber: string
    };
    position: {
        lat: number;
        lon: number;
    };
    viewport: {
        topLeftPoint: { lat: number; lon: number };
        btmRightPoint: { lat: number; lon: number };
    };
    entryPoints: {
        type: string;
        position: { lat: number; lon: number };
    }[];
}

export const AddressValueSchema = z.discriminatedUnion('manual', [
    z.object({
        manual: z.literal(true), value: z.object({
            address: z.string().min(1),
            city: z.string().min(1),
            cap: z.string().min(1),
            province: z.string().min(1)
        })
    }),
    z.object({
        manual: z.literal(false), value: z.object({
            label: z.string(),
            value: z.string()
        })
    })
])
