import React, {useState} from 'react';
import {
    BookOutlined,
    CreditCardOutlined,
    MobileOutlined,
    NotificationOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    StockOutlined, SwapOutlined,
} from '@ant-design/icons';
import {Layout, Menu, theme} from 'antd';
import {green, red} from "@ant-design/colors";
import {useLocation, useNavigate} from "react-router-dom";
import {completed, publishedComputed, subscription, welcome} from "../App";
import SubscriptionCheckBanner from "../components/SubscriptionCheckBanner";
import TopInfoBanner from "../components/TopInfoBanner";
import {COLORS} from "../utils/colors";
import {MenuItemType} from "antd/lib/menu/interface";

const {Content, Sider} = Layout;

function RedDot() {
    return (
        <span style={{
            position: 'relative',
            width: 7,
            height: 7,
            backgroundColor: "red",
            borderRadius: 50,
            display: 'inline-block',
            top: -6
        }}/>
    )
}

const MainLayout = ({children}: any) => {

    const [collapsed, setCollapsed] = useState(false);

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const navigate = useNavigate()
    const location = useLocation()


    const menuItems: MenuItemType[] = [{
        icon: <StockOutlined/>, key: '/analytics', label: 'Analitiche', onClick: () => {
            navigate('/analytics')
        }
    }, {
        icon: <SwapOutlined/>, key: '/transactions', label: 'Transazioni', onClick: () => {
            navigate('/transactions')
        }
    }, {
        icon: <NotificationOutlined/>,
        key: '/notifications',
        label: 'Notifiche',
        onClick: () => {
            navigate('/notifications')
        },
    }, {
        icon: <MobileOutlined/>,
        key: '/customize',
        label: <div>Personalizza{!completed.value && <RedDot/>}</div>,
        onClick: () => {
            navigate('/customize')
        }

    }, {
        icon: <BookOutlined/>, key: '/resources', label: 'Guida', onClick: () => {
            navigate('/resources')
        }
    }, {
        icon: <CreditCardOutlined/>,
        key: '/billings',
        label: <div>Abbonamento{subscription.value.status === 'no subscription' && <RedDot/>}</div>,
        onClick: () => {
            navigate('/billings')
        }
    },
        {
            icon: <SettingOutlined/>,
            key: '/settings',
            label: 'Impostazioni',

            onClick: () => {
                navigate('/settings')
            }
        }, {
            icon: <div style={{
                width: 10,
                height: 15,
                backgroundColor: publishedComputed.value ? green[6] : red[5],
                borderRadius: 20
            }}/>,
            key: '/status',
            label: publishedComputed.value ? 'Pubblico' : 'Nascosto',
            style: {
                position: 'absolute', bottom: 55, fontWeight: 700, color: publishedComputed.value ? green[6] : red[5],
            },
            onClick: () => {
                navigate('/settings')
            }
        }, {
            icon: <QuestionCircleOutlined/>,
            key: '/help',
            label: 'Aiuto',
            style: {
                position: 'absolute', bottom: 15,
            },
            onClick: () => {
                window.open('https://nextisfree.io/help')
            }
        }]

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Layout>
                <Sider
                    collapsedWidth={60}
                    theme={'dark'}
                    breakpoint={'lg'}
                    onCollapse={(state) => {
                        setCollapsed(state)
                    }}
                    width={200}
                    style={{
                        minHeight: 10,
                        height: '100vh',
                        position: 'relative'
                    }}
                >
                    <div style={{
                        position: 'fixed',
                        borderRight: `1px solid ${COLORS.lightGray}`,
                        height: '100vh',
                        minHeight: 500,
                        width: collapsed ? 60 : 200,
                        backgroundColor: 'white'
                    }}>
                        <div style={{padding: '40px 20px'}}>
                            {
                                !collapsed ? <img height={60} src={'/logo.png'}/> :
                                    <img width={'100%'} src={'/favicon.ico'}/>
                            }

                        </div>
                        <Menu
                            mode='inline'
                            selectedKeys={[location.pathname]}
                            style={{
                                padding: 0,
                                backgroundColor: 'white',
                            }}
                            items={menuItems}
                        />
                    </div>
                </Sider>
                <Layout
                    style={{backgroundColor: 'white'}}
                >
                    {!completed.value &&
                        <TopInfoBanner onClick={() => navigate('/customize')}
                                       text={", completa il profilo per essere visibile nell'app"}
                                       boldText={'Il tuo negozio è nascosto'} buttonText={'Completa profilo'}/>
                    }
                    <SubscriptionCheckBanner/>
                    <Content
                        style={{
                            padding: 24,
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};
export default MainLayout;
