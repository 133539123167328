import {Alert, Card, Flex, Input, Radio} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {authGet} from "../utils/authCalls";
import {useDebouncedCallback} from "use-debounce";
import {useEffect, useState} from "react";
import {z} from "zod";
import Loader from "./Loader";
import {blue, gray, green, grey} from "@ant-design/colors";

interface PromoCodeInputProps {
}

const SubscriptionSelector = ({value, onChange, withoutFreeTrial, withoutPromoCode}: any) => {
    const [valid, setValid] = useState<boolean | undefined>(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>({});
    const [subscriptionType, setSubscriptionType] = useState<'yearly' | 'monthly'>('yearly');

    function checkCode(code: string) {
        setData({})
        setValid(false)
        onChange({promoCode: code, subscriptionType: value.subscriptionType})
        setLoading(true)
        searchCode(code)
    }

    const searchCode = useDebouncedCallback(async (text: string) => {
        if (!text) {
            setLoading(false)
            setValid(undefined)
            return
        }
        try {
            await z.string().min(3).parseAsync(text)
            const response = await authGet(`/v1/promo-codes/check/${text}`)
            if (response.data === 'notFound') {
                setValid(false)
                setLoading(false)
            } else {
                setData({...response.data})
                setValid(true)
                setSubscriptionType(response.data.price_lookup_key)
                setLoading(false)
            }
        } catch (e) {
            setValid(false)
            setLoading(false)
        }
    }, 500)

    useEffect(() => {
        if (value.promoCode) {
            checkCode(value.promoCode)
        }
    }, [])

    return (
        <div>
            {!withoutPromoCode &&
                <Input value={value.promoCode} style={{borderColor: valid ? green[5] : undefined}}
                       onChange={(e) => checkCode(e.target.value)} placeholder={'Codice sconto'}
                       suffix={loading ? <Loader/> : !value.promoCode ? <div></div> : valid ?
                           <CheckOutlined style={{color: green[6]}}/> : valid === false ?
                               <CloseOutlined style={{color: 'red'}}/> : <div></div>}/> //must be an empty div otherwise it will lose focus
            }
            <Flex gap={10} style={{marginTop: 10, flexDirection: 'column'}}>
                {data?.title_it &&
                    <SubscriptionCard price_time_range={data?.price_time_range_it} subscriptionType={subscriptionType}
                                      disclaimer={data?.disclaimer_it}
                                      setSubscriptionType={setSubscriptionType} oldPrice={data?.old_price}
                                      price={data?.new_price}
                                      title={data?.title_it} price_lookup_key={data.price_lookup_key}/>
                }
                <SubscriptionCard price_time_range={'Mese + Iva'} disabled={valid}
                                  subscriptionType={value?.subscriptionType}
                                  disclaimer={`Fatturato annualmente ${!withoutFreeTrial ? 'dopo 30 giorni di prova gratuita' : ''}`}
                                  setSubscriptionType={(type) => {
                                      onChange({...value, subscriptionType: type})
                                  }} oldPrice={'49'} price={'39'}
                                  title={'Annuale'} price_lookup_key={'yearly'} badge={'Risparmia il 20%'}/>
                <SubscriptionCard price_time_range={'Mese + Iva'} disabled={valid}
                                  subscriptionType={value?.subscriptionType}
                                  disclaimer={`Fatturato mensilmente ${!withoutFreeTrial ? 'dopo 30 giorni di prova gratuita' : ''}`}
                                  setSubscriptionType={(type) => {
                                      onChange({...value, subscriptionType: type})
                                  }} price={'49'} title={'Mensile'}
                                  price_lookup_key={'monthly'}/>
            </Flex>
        </div>
    );
}
export default SubscriptionSelector;

interface SubscriptionCardProps {
    subscriptionType: 'yearly' | 'monthly',
    setSubscriptionType: (value: 'yearly' | 'monthly') => void,
    oldPrice?: string,
    price: string,
    disclaimer?: string,
    title: string,
    price_lookup_key: 'yearly' | 'monthly',
    badge?: string
    price_time_range: string,
    disabled?: boolean
}

function SubscriptionCard({
                              subscriptionType,
                              setSubscriptionType,
                              oldPrice,
                              price,
                              disclaimer,
                              title,
                              badge,
                              price_lookup_key,
                              disabled,
                              price_time_range,
                          }: SubscriptionCardProps) {
    return (
        <Card
            size={'small'}
            onClick={() => {
                !disabled && setSubscriptionType(price_lookup_key);
            }}
            style={{
                flex: 1,
                cursor: "pointer",
                backgroundColor: subscriptionType === price_lookup_key && !disabled ? blue[0] : "#f7f7f7",
                borderColor:
                    subscriptionType === price_lookup_key && !disabled ? blue[2] : "#f7f7f7",
            }}
        >
            {badge &&
                <div
                    style={{
                        backgroundColor: disabled ? grey[0] : green[1],
                        width: "fit-content",
                        position: "absolute",
                        right: 10,
                        top: 10,
                        padding: "1px 8px",
                        color: disabled ? 'white' : green[7],
                        fontSize: 13,
                        fontWeight: 500,
                        borderRadius: 30,
                        border: "solid 1px",
                        borderColor: disabled ? grey[0] : green[2],
                    }}
                >
                    {badge}
                </div>
            }

            <Flex align={'center'} gap={10}>
                <Flex align={'center'} justify={'center'}
                      style={{minWidth: 20, height: 20, backgroundColor: 'white', borderRadius: 20}}>
                    <div style={{
                        width: 10,
                        height: 10,
                        backgroundColor: subscriptionType === price_lookup_key && !disabled ? blue[5] : 'white',
                        borderRadius: 20
                    }}/>
                </Flex>
                <div>
                    <h3
                        style={{
                            fontSize: 15,
                            color: disabled ? grey[0] : subscriptionType === price_lookup_key ? blue[6] : "black",
                        }}
                    >
                        {title}
                    </h3>
                    <p style={{color: disabled ? grey[3] : "black"}}>
                        <b style={{fontSize: 16}}>
                            {oldPrice && <s style={{color: gray[3]}}>{oldPrice}€</s>} {price}€
                        </b>
                        /{price_time_range}
                    </p>
                    <p
                        style={{
                            fontSize: 13,
                            color: gray[5],
                            marginTop: 5,
                            lineHeight: 1,
                        }}
                    >
                        {disclaimer}
                    </p>
                </div>
            </Flex>
        </Card>
    )
}
