import React, {useEffect, useState} from "react";
import {authGet} from "../utils/authCalls";
import {subscription} from "../App";
import {Button, message, Modal} from "antd";
import {orange, red} from "@ant-design/colors";
import {useNavigate} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import TopInfoBanner from "./TopInfoBanner";
import {openStripePortal, updatePaymentMethod} from "../utils/stripe";
import {createStyles} from "antd-style";
import PaymentForm from "./onBoarding/PaymentForm";

interface SubscriptionCheckBannerProps {
}

const SubscriptionCheckBanner = () => {
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(true);


    useEffect(() => {
        authGet("/v1/subscriptions").then((results) => {
            subscription.value = results.data;
            setFetching(false)
        });
    }, []);

    const navigate = useNavigate()

    if (fetching) return null;

    if (subscription.value.status === 'incomplete') {
        return (
            <Modal footer={''} styles={{
                mask: {
                    backdropFilter: 'blur(5px)',
                }
            }} open closable={false}>
                <div>
                    <h2 style={{textAlign: 'center'}}>Completa il pagamento per continuare</h2>
                    <PaymentForm {...subscription.value}/>
                </div>
            </Modal>
        )
    }

    if (subscription.value.status === "no subscription") {
        return (
            <TopInfoBanner
                boldText={"Non hai un abbonamento attivo "}
                text={""}
                buttonText={"Abbonati ora"}
                onClick={() => navigate('/billings')}
                buttonLoading={loading}
            />
        );
    }

    if (!subscription.value.default_payment_method)
        return (
            <TopInfoBanner
                boldText={"Nessun metodo di pagamento "}
                text={", inserisci un metodo di pagamento valido"}
                buttonText={"Aggiungi metodo di pagamento"}
                onClick={() => {
                    setLoading(true);
                    updatePaymentMethod();
                }}
                buttonLoading={loading}
            />
        );
    else return null
};
export default SubscriptionCheckBanner;
