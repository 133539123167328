import React, {useEffect, useRef, useState} from 'react';
import {CloudUploadOutlined} from "@ant-design/icons";
import Cropper, {Area} from 'react-easy-crop'
import '../styles/imageInput.css'
import {Button, ColorPicker, Flex, Modal, Skeleton, Slider} from "antd";
import Wrapper from "./Wrapper";
import getCroppedImg from "../utils/getCroppedImage";
import {extractColors} from "extract-colors";
import {COLORS} from "../utils/colors";
import {isColorTooBright} from "../utils/colorCheck";
import {createObjectUrlFromRemoteImage} from "../utils/imageCompression";

const ImageUploadInput = ({
                              setImage,
                              image,
                              backgroundColor = 'grey',
                              imagePreviewUrl,
                              setImagePreviewUrl,
                              objectFit = 'contain',
                              setColor,
                              color,
                              colorPickerError,
                              setColorPickerError
                          }: any) => {

    const fileInputRef = useRef(null);

    const [crop, setCrop] = useState({x: 0, y: 0})
    const [imageLoading, setImageLoading] = useState(true);
    const [zoom, setZoom] = useState(1)
    const [imageToCrop, setImageToCrop] = useState<string>('');
    const [cropperOpen, setCropperOpen] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({width: 0, height: 0, y: 0, x: 0})
    const [colorSwatches, setColorSwatches] = useState<any>(undefined);

    useEffect(() => {
        if (imagePreviewUrl && setColor) {
            createObjectUrlFromRemoteImage(imagePreviewUrl).then((url: any) => {
                extractColors(url, {colorValidator: (red, green, blue) => !isColorTooBright(red, green, blue)}).then((colors) => {
                    setColorSwatches(colors)
                })
            });
        }
    }, []);

    const handleChange = (e: any) => {
        if (e.target.files[0]) {
            setCropperOpen(true)
            setImageToCrop(URL.createObjectURL(e.target.files[0]))
            e.target.value = null; //resettare l'input permette di caricare due volte lo stesso file
        }
    };

    const handleDragOver = (event: any) => {
        event.preventDefault(); // Necessary to allow drop
        event.stopPropagation();
    };

    const handleDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files[0]) {
            setCropperOpen(true)
            setImageToCrop(URL.createObjectURL(event.dataTransfer.files[0]))
        }
    };

    const cropImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageToCrop,
                croppedAreaPixels,
            )
            setZoom(1)
            setImagePreviewUrl(croppedImage.url)
            setImage(croppedImage.file)
            setCropperOpen(false)
            const colors = await extractColors(croppedImage.url, {colorValidator: (red, green, blue) => !isColorTooBright(red, green, blue)})
            setColorSwatches(colors)
            setColor(colors[0].hex)
        } catch (e) {
            console.error(e)
        }
    }

    const isCustomColor = !colorSwatches?.some((colorSwatch: any) => colorSwatch.hex === color)

    function onColorPickerChange(color: any, hex: string) {
        const rgb = color.toRgb();
        if (isColorTooBright(rgb.r, rgb.g, rgb.b)) {
            setColorPickerError(true)
        } else {
            setColorPickerError(false)
            setColor(hex)
        }
    }

    return (
        <>
            <Modal onOk={() => cropImage()} title={<div/>} closable={false} onCancel={()=>setCropperOpen(false)} cancelText={'Annulla'} open={cropperOpen}>
                <div style={{position: 'relative', height: 300}}>
                    <Cropper
                        onCropComplete={(croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
                        aspect={1.4} style={{containerStyle: {borderRadius: 5}}} image={imageToCrop}
                        onCropChange={setCrop} crop={crop}
                        zoom={zoom}/>
                </div>
                <Flex align={'center'} gap={10}>
                    <p>Zoom</p>
                    <Slider value={zoom} style={{flex: 1}} tooltip={{formatter: null}} onChange={(val) => {
                        setZoom(val)
                    }} step={0.1} min={1} max={2}/>
                </Flex>
            </Modal>
            <div onDragOver={handleDragOver}
                 onDrop={handleDrop} className={'inputWrapper'}>
                <label htmlFor={'input'} style={{cursor: 'pointer'}}>
                    <div
                        className={!imagePreviewUrl || imagePreviewUrl === 'error' ? 'uploadLabel' : 'uploadLabel transparent'}>
                        <CloudUploadOutlined style={{fontSize: 20}}/>
                        <div style={{textAlign: 'center'}}>
                            <p>Carica immagine</p>
                            <p style={{fontSize: 12, marginTop: 5}}>1000x1400</p>
                        </div>
                    </div>
                    <>
                        {
                            imagePreviewUrl && !cropperOpen &&
                            <img
                                onLoad={() => {
                                    setImageLoading(false)
                                }} src={imagePreviewUrl} width={'100%'}
                                style={{objectFit: objectFit, display: "block"}}/>
                        }
                    </>
                </label>
                <input ref={fileInputRef} accept={'.png,.jpeg,.jpg'} id={'input'} type={'file'} onChange={handleChange}
                       style={{display: 'none'}}/>
            </div>
            {colorSwatches && setColor && (
                <Flex style={{marginTop: 10}} gap={5} align={'center'}>
                    <Flex
                        className={colorPickerError ? 'colorPicker error' : isCustomColor ? 'colorPicker selected' : 'colorPicker'}
                        align={'center'} gap={5}>
                        <ColorPicker
                            value={color}
                            onChange={onColorPickerChange}
                            disabledAlpha
                            style={{border: 'none', padding: 0, backgroundColor: 'transparent', outline: 'none'}}
                        />
                        <p>{colorPickerError ? 'troppo chiaro' : color}</p>
                    </Flex>
                    <Flex gap={5}>
                        {colorSwatches.map((colorSwatch: any, index: number) => (
                            <div onClick={() => {
                                setColor(colorSwatch.hex);
                                setColorPickerError(false)
                            }}
                                 className={color === colorSwatch.hex ? 'colorSwatch selected' : 'colorSwatch'}
                                 key={index}
                            >
                                <div style={{
                                    backgroundColor: colorSwatch.hex,
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: 20
                                }}/>
                            </div>
                        ))}
                    </Flex>
                </Flex>
            )}
        </>
    );
};
export default ImageUploadInput;
