import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { gray } from "@ant-design/colors";
import * as Sentry from "@sentry/react";
import {COLORS as ColorS, COLORS} from './utils/colors'

Sentry.init({
  dsn: "https://7328ce4e99acc19e2f3e6019f3afe937@o4506761687334912.ingest.sentry.io/4506773572616192",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.nextisfree\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
 <React.StrictMode>
      <link
          rel="apple-app-site-association"
          href="%PUBLIC_URL%/.well-known/apple-app-site-association"
      />
      <ConfigProvider
          theme={{
            token: {
              colorPrimary: COLORS.primary,
              colorBgContainer: COLORS.lightGray,
            },
            components: {
              Menu: {
                fontWeight: 900,
                itemBorderRadius: 2,
                fontSize: 15,
                itemSelectedBg: COLORS.primary,
                itemSelectedColor: COLORS.black,
                itemColor: gray[6],
              },
              Input: {
                colorBgContainer: "white",
                borderRadius: 5,
                padding: 0,
                controlHeight: 34,
                controlHeightLG: 40,
                fontSizeLG: 15,
                colorBorder: COLORS.mediumGray,
                colorBgContainerDisabled: COLORS.lightGray,
                colorTextDisabled: COLORS.gray
              },
              InputNumber: {
                colorBgContainer: "white",
                addonBg: 'white',
                controlHeight: 34,
                colorBorder: COLORS.mediumGray,
                borderRadius: 5,
                colorBgContainerDisabled: COLORS.lightGray,
                colorTextDisabled: COLORS.gray
              },
              Select: {
                colorBgContainer: "white",
                borderRadiusLG: 5,
                borderRadius: 5,
                controlHeight: 34,
                fontSizeLG: 15,
                colorBorder: COLORS.mediumGray,
                colorTextDisabled: COLORS.gray
              },
              DatePicker: {
                colorBgContainer: "white",
                colorBorder: COLORS.mediumGray,
              },
              Form: {
                verticalLabelMargin: 0,
                verticalLabelPadding: 2,
                itemMarginBottom: 12,
                labelColor: gray[6],
                itemMarginTop: 0
              },
              Button: {
                defaultShadow: '',
                primaryShadow: '',
                borderRadius: 20,
                lineWidth: 0,
                defaultHoverColor: COLORS.gray,
                defaultActiveBg: COLORS.mediumGray,
                defaultActiveColor: COLORS.darkBg,
                defaultColor: 'black',
                defaultBg: COLORS.lightGray,
                defaultHoverBg: COLORS.lightGray,
                colorPrimaryBg: COLORS.black,
                primaryBg: 'red',
                borderRadiusLG: 5,
                fontSizeLG: 14,
                colorPrimary: 'black',
                colorPrimaryBgHover: COLORS.lightGray,
                colorPrimaryHover: COLORS.darkBg,
                colorPrimaryActive: COLORS.gray,
                borderRadiusSM: 20,
              },
              Message: {},
              Table: {
                headerBg: COLORS.lightGray,
                colorPrimaryBg: 'red',
                rowHoverBg: 'white',
                colorBgContainer: 'white',
                tableBorderRadius: 1,
                headerBorderRadius: 0
              },
              Slider: {
                trackBg: COLORS.black,
                trackHoverBg: COLORS.gray,
                dotBorderColor: COLORS.black,
                handleColor: COLORS.black,
                handleActiveColor: COLORS.black,
                handleActiveOutlineColor: 'transparent',
              }
            },
          }}
      >
        <App/>
      </ConfigProvider>

   </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
