import {Button, Flex, Input, Select} from "antd";
import {useState} from "react";
import axios from "axios";
import {trackEvent} from "../utils/Matomo/tracking_functions";

const AddressInput = ({value, onChange, onlyManual}: any) => {

    const [options, setOptions] = useState([]);

    const fetchAddresses = async (search: string) => {
        if (!search) {
            return
        }
        const apiKey = 'AIzaSyCyn_Mij0VVEDJkI-ygvsnW1RbvV5xrGxA';
        try {
            const results = await axios.post('https://places.googleapis.com/v1/places:searchText', {textQuery: search}, {
                headers: {
                    "Content-Type": 'application/json',
                    'X-Goog-Api-Key': apiKey,
                    'X-Goog-FieldMask': 'places.id,places.formattedAddress'
                }
            })

            const mappedResults = results.data.places.map((item: any) => (
                {value: item.id, label: item.formattedAddress}
            ))
            setOptions(mappedResults)
        } catch (e) {
            console.log(e)
        }
    };



    function onSelect(value: any) {
        onChange({value: value, manual: false})
    }

    function onManualInput(text: string, inputName: string){
        const nextValue = {...value?.value, [inputName]: text}
        onChange({value: nextValue, manual: true})
        trackEvent('Address Input', 'Manual Input', text ?? 'empty')
    }

    return (
        <div>
            {value?.manual || onlyManual ?
                <div>
                    <Flex gap={10}>
                        <Input defaultValue={value?.value?.address} onChange={(e)=>{onManualInput(e.target.value, 'address')}} placeholder={'Indirizzo'}/>
                    </Flex>
                    <Input style={{marginTop: 6}} defaultValue={value?.value?.city} onChange={(e)=>{onManualInput(e.target.value, 'city')}} placeholder={'Città'}/>
                    <Flex style={{marginTop: 6}} gap={10}>
                        <Input defaultValue={value?.value?.cap} onChange={(e)=>{onManualInput(e.target.value, 'cap')}} placeholder={'CAP'}/>
                        <Input defaultValue={value?.value?.province} onChange={(e)=>{onManualInput(e.target.value, 'province')}} placeholder={'Provincia'}/>
                    </Flex>
                </div>:
                <div>
                    <Select
                        value={value?.value}
                        filterOption={false}
                        notFoundContent={<p style={{textAlign: 'center', color: 'grey', fontSize: 12}}>Cerca un indirizzo</p>}
                        showSearch
                        onSearch={fetchAddresses}
                        onSelect={onSelect}
                        options={options}
                        labelInValue={true}
                        placeholder={"Via Rossi 21, Roma"}
                    />
                    <a style={{fontSize: 13, marginLeft: 10, paddingTop: 10}} onClick={() => {
                        onChange({value: {}, manual: true})
                    }}>Non trovo il mio indirizzo</a>
                </div>
            }
        </div>);
}
export default AddressInput;
