import {authGet, authPost, authPut} from "./authCalls";
import {z} from "zod";
import {SalesPointSchema, SalesPointType} from "@tipubly/nif-types";
import {message} from "antd";


export function openStripePortal(type: string){
    authGet(`/v1/subscriptions/customer-portal/${type}`).then((response)=>{window.location.replace(response.data.url)});
}

export function cancelSubscription(subscription_id: string | undefined){
    authPut(`/v1/subscriptions/cancel-subscription`, {subscription_id}).then(()=>{window.location.reload()});
}

export function updatePaymentMethod(){
    authGet(`/v1/subscriptions/update-payment-method`).then((response)=>{window.location.replace(response.data.url)});
}

export function renewSubscription(subscription_id: string | undefined){
    authPut(`/v1/subscriptions/renew-subscription/${subscription_id}`).then(()=>{window.location.reload()});
}

export function addSalesPoint(body: SalesPointType){

    try{
        SalesPointSchema.omit({}).parse(body)
    }catch (e) {
        message.error('Qualcosa è andato storto').then()
        throw e;
    }

    authPost('/v1/subscriptions/sales-point', body).then(()=>window.location.reload())
}

